import { lazy } from 'react';

// project-imports
import MainLayout from 'layout/MainLayout';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import ComingSoon from 'pages/maintenance/coming-soon/coming-soon2';

const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/error/404')));
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/error/500')));
const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction/under-construction')));
const MaintenanceComingSoon = Loadable(lazy(() => import('pages/maintenance/coming-soon/coming-soon')));

// render - sample page
const SamplePage = Loadable(lazy(() => import('pages/extra-pages/sample-page')));
const PatientsPage = Loadable(lazy(() => import('pages/extra-pages/patients-page')));
const ResidentsPage = Loadable(lazy(() => import('pages/extra-pages/residents-page')));
const ConsultantsPage = Loadable(lazy(() => import('pages/extra-pages/consultants-page')));
const AuditLogPage = Loadable(lazy(() => import('pages/extra-pages/audit-log-page')));
const AppointmentsPage = Loadable(lazy(() => import('pages/extra-pages/appointments-page')));
const ViewAppointmentPage = Loadable(lazy(() => import('pages/extra-pages/view-appointment-page')));

const PatientProfile = Loadable(lazy(() => import('pages/extra-pages/view-appointment-page/profile')));
const PatientSOAP = Loadable(lazy(() => import('pages/extra-pages/view-appointment-page/soap')));
const PatientPAF = Loadable(lazy(() => import('pages/extra-pages/view-appointment-page/paf')));
const AppointmentPDF = Loadable(lazy(() => import('pages/extra-pages/view-appointment-page/pdf')));
const PatientDischarge = Loadable(lazy(() => import('pages/extra-pages/view-appointment-page/discharge')));
const PatientDocuments = Loadable(lazy(() => import('pages/extra-pages/view-appointment-page/documents')));
const ViewPatientPage = Loadable(lazy(() => import('pages/extra-pages/view-patient-page')));

const PatientProfile2 = Loadable(lazy(() => import('pages/extra-pages/view-patient-page/profile')));
const PatientDiagnosis= Loadable(lazy(() => import('pages/extra-pages/view-patient-page/diagnosis')));
const PatientAppointments = Loadable(lazy(() => import('pages/extra-pages/view-patient-page/appointments')));
const PatientMedications= Loadable(lazy(() => import('pages/extra-pages/view-patient-page/medications')));

// ==============================|| MAIN ROUTES ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'sample-page',
          element: <SamplePage />
        },
        {
          path: 'patients',
          element: <PatientsPage />,
        },
        {
          path: 'residents',
          element: <ResidentsPage />
        },
        {
          path: 'consultants',
          element: <ConsultantsPage />
        },
        {
          path: 'appointments-page',
          element: <AppointmentsPage />
        },
        {
          path: 'view-appointment-page',
          element: <ViewAppointmentPage />,
          children: [
            {
              path: 'profile/:appointment_id',
              element: <PatientProfile />
            },
            {
              path: 'soap/:appointment_id',
              element: <PatientSOAP />
            },
            {
              path: 'discharge/:appointment_id',
              element: <PatientDischarge />
            },
            {
              path: 'paf/:appointment_id',
              element: <PatientPAF/>
            },
            {
              path: 'documents/:appointment_id',
              element: <PatientDocuments />
            },
            {
              path: 'pdf/:appointment_id',
              element: <AppointmentPDF/>
            },
          ]
        },

        {
          path: 'view-patient-page',
          element: <ViewPatientPage />,
          children: [
            {
              path: 'profile/:patient_id',
              element: <PatientProfile2 />
            },
            {
              path: 'diagnosis/:patient_id',
              element: <PatientDiagnosis />
            },
            {
              path: 'medications/:patient_id',
              element: <PatientMedications />
            },
            {
              path: 'appointments/:patient_id',
              element: <PatientAppointments/>
            },
          ]
        },
      ]
    },
    {
      path: '/maintenance',
      element: <CommonLayout />,
      children: [
        {
          path: '404',
          element: <MaintenanceError />
        },
        {
          path: '500',
          element: <MaintenanceError500 />
        },
        {
          path: 'under-construction',
          element: <MaintenanceUnderConstruction />
        },
        {
          path: 'coming-soon',
          element: <MaintenanceComingSoon />
        }
      ]
    }
  ]
};

export default MainRoutes;
