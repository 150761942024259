//keys to be used in the backend
export const PAF_DB_KEYS = {
  DEMOGRAPHICS_KEY: 'paf_1_demographics',
  CHIEF_COMPLAINT_KEY: 'paf_2_chief_complaint',
  HISTORY_OF_PRESENT_ILLNESS_KEY: 'paf_3_history_illness',
  REVIEW_OF_SYTEMS_KEY: 'paf_4_review_of_systems',
  OTHER_MEDICAL_ILLNESS_KEY: 'paf_5_other_medical_illness',
  PSYCHIATRIC_SYMPTOMS_KEY: 'paf_6_psychiatric_symptoms',
  FAMILY_INFORMATION_KEY: 'paf_7_family_information',
  ACCESS_TO_COMMUNITY_RESOURCES_KEY: 'paf_8_access_to_community_resources',
  DEVELOPMENT_AND_PERSONAL_HISTORY_KEY: 'paf_9_development_and_personal_history',
  PHYSICAL_EXAMINATION_KEY: 'paf_10_physical_examination',
  NEUROLOGICAL_EXAMINATION_KEY: 'paf_11_neurological_examination',
  MENTAL_STATUS_EXAMINATION_KEY: 'paf_12_mental_status_examination',
  LABORATORY_TESTS_DONE_KEY: 'paf_13_laboratory_tests_done',
  DIAGNOSTIC_BASED_ON_DSM_5_KEY: 'paf_14_diagnostic_based_on_dsm_5',
  FURTHER_DIAGNOSTIC_PLAN_KEY: 'paf_15_further_diagnostic_plan',
  INITIAL_TREATMENT_PLAN_KEY: 'paf_16_initial_treatment_plan',
  FOLLOW_UP_KEY: 'paf_17_follow_up',
}

const PAFConstants = {
  DEMOGRAPHICS_KEY: 'paf_1_demographics',
  CHIEF_COMPLAINT_KEY: 'paf_2_chief_complaint',
  HISTORY_OF_PRESENT_ILLNESS_KEY: 'paf_3_history_illness',
  REVIEW_OF_SYTEMS_KEY: 'paf_4_review_of_systems',
  OTHER_MEDICAL_ILLNESS_KEY: 'paf_5_other_medical_illness',
  MEDICATIONS_KEY: 'medications',
  PSYCHIATRIC_SYMPTOMS_KEY: 'paf_6_psychiatric_symptoms',
  FAMILY_INFORMATION_KEY: 'paf_7_family_information',
  NUCLEAR_FAMILY_KEY: 'nuclear_family',
  EXTENDED_FAMILY_KEY: 'extended_family',
  ACCESS_TO_COMMUNITY_RESOURCES_KEY: 'paf_8_access_to_community_resources',
  DEVELOPMENT_AND_PERSONAL_HISTORY_KEY: 'paf_9_development_and_personal_history',
  PHYSICAL_EXAMINATION_KEY: 'paf_10_physical_examination',
  NEUROLOGICAL_EXAMINATION_KEY: 'paf_11_neurological_examination',
  MENTAL_STATUS_EXAMINATION_KEY: 'paf_12_mental_status_examination',
  LABORATORY_TESTS_DONE_KEY: 'paf_13_laboratory_tests_done',
  LAB_TESTS_KEY: 'lab_tests',
  DIAGNOSTIC_BASED_ON_DSM_5_KEY: 'paf_14_diagnostic_based_on_dsm_5',
  FURTHER_DIAGNOSTIC_PLAN_KEY: 'paf_15_further_diagnostic_plan',
  INITIAL_TREATMENT_PLAN_KEY: 'paf_16_initial_treatment_plan',
  FOLLOW_UP_KEY: 'paf_17_follow_up',
  PAST_HOSPITALIZATION_KEY: 'past_hospitalizations',
  OB_GYNE_KEY: 'ob_gyne',
}

export default PAFConstants;