// third-party
import { createSlice } from '@reduxjs/toolkit';

// project-imports
import axios from 'utils/axios';
import { dispatch } from '../index';
import moment from 'moment';
import { openSnackbar } from 'store/reducers/snackbar';
// ==============================|| SLICE - PRODUCTS ||============================== //

const initialState = {
  patients: [],
  currentPatient: {}
};

const slice = createSlice({
  name: 'patients',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET PATIENTS
    getPatientSuccess(state, action) {
      state.patients = action.payload;
    },

    setCurrentPatient(state, action) {
      state.currentPatient = action.payload
    }
  }
});

// Reducer
export default slice.reducer;

export function getPatients() {
  return async () => {
    try {
      const response = await axios.get('/api/patients');
      const { data } = response.data;
      dispatch(slice.actions.getPatientSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPatient(id) {
  return async () => {
    try {
      const response = await axios.get(`/api/patients/${id}`);
      const { data, success } = response.data;
      if (parseInt(success) === 1) {
        dispatch(slice.actions.setCurrentPatient(data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function save(tempParams) {
  return async () => {
    try {

      const params = { ...tempParams };

      params.datetime_admitted = params.datetime_admitted ? moment(params.datetime_admitted).format("YYYY-MM-DD HH:mm:ss") : moment().format("YYYY-MM-DD HH:mm:ss") ;
      params.birthday = params.birthday ? moment(params.birthday).format("YYYY-MM-DD") : '';

      let response = {}; 
      let action = '';
      if (params.id) {
        action = 'updated';
        response = await axios.put(`/api/patients/${params.id}`, params);
      } else {
        action = 'added';
        response = await axios.post('/api/patients', params);
      }
      const { data, success } = response.data;
      if (parseInt(success)) {
        dispatch(getPatients());
        dispatch(slice.actions.setCurrentPatient(data));
        dispatch(
          openSnackbar({
            open: true,
            message: `Patient ${action} successfully.`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: false
          })
        );
        //window.location.reload();
      } else {
        dispatch(
          openSnackbar({
            open: true,
            message: `Patient was not ${action}. Please try again.`,
            variant: 'alert',
            alert: {
              color: 'error'
            },
            close: false
          })
        );
      }
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setCurrentPatient(patient) {
  return async () => {
    dispatch(slice.actions.setCurrentPatient(patient));
  };
}