// third-party
import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

// project-imports
import axios from 'utils/axios';
import { dispatch } from '../index';
import { openSnackbar } from 'store/reducers/snackbar';

//constants 
import APPOINTMENT_KEYS from '../../pages/extra-pages/patients-page/constants';
// ==============================|| SLICE - PRODUCTS ||============================== //

const initialState = {
  appointments: [],
  currentAppointment: {},
  appointmentCalendarDate: moment().format('YYYY-MM-DD')
};

const slice = createSlice({
  name: 'appointments',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    getAppointmentsSuccess(state, action) {
      state.appointments = action.payload;
    },

    setCurrentAppointment(state, action) {
      state.currentAppointment = action.payload
    },

    setAppointmentCalendarDate(state, action) {
      state.appointmentCalendarDate = action.payload
    }
  }
});

// Reducer
export default slice.reducer;

export function getAppointments(params) {
  return async () => {
    try {

      params.sort = params.sort ? params.sort : { 'by': 'id', 'order': 'desc' };

      const response = await axios.get('/api/appointments', {params: {...params}});
      const { data } = response.data;
      dispatch(slice.actions.getAppointmentsSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAppointment(id) {
  return async () => {
    try {
      const response = await axios.get(`/api/appointments/${id}`);
      const { data, success } = response.data;
      if (parseInt(success) === 1) {

        const tempData = { ...data };
        for (const [key, value] of Object.entries(APPOINTMENT_KEYS)) {
          tempData[value] = tempData[value] ? JSON.parse(tempData[value]) : {};
        }

        dispatch(slice.actions.setCurrentAppointment(tempData));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function save(tempParams, isAutoSave=false) {
  return async () => {
    //console.log('123', tempParams)
    try {
      let response = {};
      const params = { ...tempParams };

      tempParams.resident_id = tempParams.resident_id || '';

      //stringify all paf keys
      for (const [key, value] of Object.entries(APPOINTMENT_KEYS)) {
        params[value] = params[value] ? JSON.stringify(params[value]) : '';
      }
      
      
      let action = '';
      if (params.id) {
        action = 'updated';
        response = await axios.put(`/api/appointments/${params.id}`, params);
      } else {
        action = 'added';
        response = await axios.post('/api/appointments', params);
      }

      const { data, success } = response.data;
      if (parseInt(success) === 1) {
        dispatch(getAppointments());
      

        const tempData = { ...data };
        for (const [key, value] of Object.entries(APPOINTMENT_KEYS)) {
          tempData[value] = tempData[value] ? JSON.parse(tempData[value]) : {};
        }

        if (!isAutoSave) {
          dispatch(slice.actions.setCurrentAppointment(tempData));
          dispatch(
            openSnackbar({
              open: true,
              message: `Appointment ${action} successfully.`,
              variant: 'alert',
              alert: {
                color: 'success'
              },
              close: false
            })
          );
        }

      
      
        
        //window.location.reload();
      } else {
        dispatch(
          openSnackbar({
            open: true,
            message: `Appointment was not ${action}. Please try again.`,
            variant: 'alert',
            alert: {
              color: 'error'
            },
            close: false
          })
        );
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setCurrentAppointment(tempAppointment) {
  return async () => {
    let appointment = { ...tempAppointment };

    for (const [key, value] of Object.entries(APPOINTMENT_KEYS)) {
      appointment[value] = appointment[value] ? JSON.parse(appointment[value]) : {};
    }

    dispatch(slice.actions.setCurrentAppointment(appointment));
  };
}


export function deleteAppointment(tempParams) {
  return async () => {
    try {
      let response = {};
      const params = { ...tempParams };
      response = await axios.delete(`/api/appointments/${params.id}`, );
      const {success } = response.data;
      if (parseInt(success) === 1) {
        dispatch(getAppointments(tempParams));
    
        dispatch(
          openSnackbar({
            open: true,
            message: `Appointment deleted successfully.`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: false
          })
        );
        //window.location.reload();
      } else {
        dispatch(
          openSnackbar({
            open: true,
            message: `Appointment was not deleted. Please try again.`,
            variant: 'alert',
            alert: {
              color: 'error'
            },
            close: false
          })
        );
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setAppointmentCalendarDate(schedule) {
  return async () => {
    dispatch(slice.actions.setAppointmentCalendarDate(schedule));
  };
}

