// material-ui
import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoIconDark from 'assets/images/logo-icon-dark.svg';
 * import logoIcon from 'assets/images/logo-icon.svg';
 *
 */

// ==============================|| LOGO ICON SVG ||============================== //

const LogoIcon = () => {
  const theme = useTheme();

  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={theme.palette.mode === ThemeMode.DARK ? logoIconDark : logoIcon} alt="icon logo" width="100" />
     *
     */
    <svg width="66px" height="28px" viewBox="0 0 1024 1024" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <path d="M959.2 931.3c0 4.2-3.5 7.7-7.7 7.7H72c-4.2 0-7.7-3.5-7.7-7.7v-27.6c0-4.2 3.5-7.7 7.7-7.7h879.5c4.2 0 7.7 3.5 7.7 7.7v27.6z" fill="#333E48" />
      <path d="M951.5 896H540.4v43h411.1c4.2 0 7.7-3.5 7.7-7.7v-27.6c0-4.3-3.4-7.7-7.7-7.7z" fill="#00AD68" />
      <path d="M133.6 183.6h432v712.3h-432z" fill="#D1D3D3" />
      <path d="M133.6 183.6h432v98.6h-432z" fill="" /><path d="M187.6 613.8h324v87.8h-324z" fill="#FFFFFF" />
      <path d="M187.6 613.8h324v32.3h-324z" fill="" /><path d="M335.3 613.8h28.6v87.8h-28.6z" fill="#333E48" />
      <path d="M201.9 687.3h295.3v-59.2H201.9v59.2zM511.6 716h-324c-7.9 0-14.3-6.4-14.3-14.3v-87.8c0-7.9 6.4-14.3 14.3-14.3h324c7.9 0 14.3 6.4 14.3 14.3v87.8c0 7.8-6.4 14.3-14.3 14.3z" fill="#0071CE" /><path d="M187.6 469h324v87.8h-324z" fill="#FFFFFF" />
      <path d="M187.6 469h324v32.3h-324z" fill="" />
      <path d="M335.3 469h28.6v87.8h-28.6z" fill="#333E48" />
      <path d="M201.9 542.5h295.3v-59.2H201.9v59.2z m309.7 28.7h-324c-7.9 0-14.3-6.4-14.3-14.3V469c0-7.9 6.4-14.3 14.3-14.3h324c7.9 0 14.3 6.4 14.3 14.3v87.8c0 8-6.4 14.4-14.3 14.4z" fill="#0071CE" /><path d="M187.6 324.2h324V412h-324z" fill="#FFFFFF" />
      <path d="M187.6 324.2h324v32.3h-324z" fill="" />
      <path d="M335.3 324.2h28.6V412h-28.6z" fill="#333E48" /><path d="M201.9 397.8h295.3v-59.2H201.9v59.2z m309.7 28.6h-324c-7.9 0-14.3-6.4-14.3-14.3v-87.8c0-7.9 6.4-14.3 14.3-14.3h324c7.9 0 14.3 6.4 14.3 14.3v87.8c0 7.9-6.4 14.3-14.3 14.3z" fill="#0071CE" />
      <path d="M266.1 772.8h167V896h-167z" fill="#FFFFFF" />
      <path d="M335.3 772.8h28.6V896h-28.6z" fill="#333E48" />
      <path d="M447.4 896h-28.6V787.1H280.4V896h-28.6V772.8c0-7.9 6.4-14.3 14.3-14.3h167c7.9 0 14.3 6.4 14.3 14.3V896z" fill="#0071CE" />
      <path d="M579.1 148.5H444.4v-44.1c0-10.5-8.6-19.1-19.1-19.1H273.9c-10.5 0-19.1 8.6-19.1 19.1v44.1H120.1c-10.5 0-19.1 8.6-19.1 19.1v74.5c0 10.5 8.6 19.1 19.1 19.1h459c10.5 0 19.1-8.6 19.1-19.1v-74.5c0-10.5-8.6-19.1-19.1-19.1z" fill="#FF5959" />
      <path d="M395.8 151.3h-24.1v-24.1c0-5.3-4.3-9.5-9.5-9.5H337c-5.3 0-9.5 4.3-9.5 9.5v24.1h-24.1c-5.3 0-9.5 4.3-9.5 9.5v25.1c0 5.3 4.3 9.5 9.5 9.5h24.1v24.1c0 5.3 4.3 9.5 9.5 9.5h25.1c5.3 0 9.5-4.3 9.5-9.5v-24.1h24.1c5.3 0 9.5-4.3 9.5-9.5v-25.1c0.1-5.2-4.1-9.5-9.4-9.5z" fill="#FFFFFF" />
      <path d="M565.6 571.3h324.2V896H565.6z" fill="#D1D3D3" />
      <path d="M619.7 662.4h216v87.8h-216z" fill="#FFFFFF" />
      <path d="M619.7 662.4h216v32.3h-216z" fill="" />
      <path d="M713.4 662.4H742v87.8h-28.6z" fill="#333E48" />
      <path d="M634 735.9h187.3v-59.2H634v59.2z m201.7 28.7h-216c-7.9 0-14.3-6.4-14.3-14.3v-87.8c0-7.9 6.4-14.3 14.3-14.3h216c7.9 0 14.3 6.4 14.3 14.3v87.8c0 7.8-6.4 14.3-14.3 14.3z" fill="#0071CE" />
      <path d="M565.6 515.4h341.7c10.5 0 19.1 8.6 19.1 19.1v36.6c0 10.5-8.6 19.1-19.1 19.1H565.6v-74.8z" fill="#FF5959" />
      <path d="M601.7 590.2v-74.8h-36.1V896h36.1V626.6h288.1v-36.4z" fill="" />
      <path d="M819.5 896c0-33.8-27.4-61.1-61.1-61.1-33.8 0-61.1 27.4-61.1 61.1h122.2z" fill="#218649" />
    </svg>
  );
};

export default LogoIcon;
