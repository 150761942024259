// third-party
import { combineReducers } from 'redux';

// project-imports
import menu from './menu';
import snackbar from './snackbar';
import patients from './patients';
import consultants from './consultants';
import residents from './residents';
import appointments from './appointments';
import medications from './medications';
import diagnosis from './diagnosis';
// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  menu,
  snackbar,
  patients,
  consultants,
  residents,
  appointments,
  medications,
  diagnosis
});

export default reducers;
