// third-party
import { createSlice } from '@reduxjs/toolkit';

// project-imports
import axios from 'utils/axios';
import { dispatch } from '../index';
import { openSnackbar } from 'store/reducers/snackbar';
// ==============================|| SLICE - PRODUCTS ||============================== //

const initialState = {
  diagnosis: [], //with patients associations
  currentDiagnosis: {},
  diagnosisFromDB: [] //for autocomplete
};

const slice = createSlice({
  name: 'diagnosis',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET PATIENTS
    getDiagnosisSuccess(state, action) {
      state.diagnosis = action.payload;
    },

    setCurrentDiagnosis(state, action) {
      state.currentDiagnosis = action.payload
    },

    getDiagnosisFromDBSuccess(state, action) {
      state.diagnosisFromDB = action.payload;
    },
  }
});

// Reducer
export default slice.reducer;

export function getDiagnosis(params) {
  return async () => {
    try {
      params.sort = params.sort ? params.sort : { 'by': 'date', 'order': 'desc' };

      const response = await axios.get('/api/diagnosis', { params: { ...params } });
  
      const { data } = response.data;
      dispatch(slice.actions.getDiagnosisSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDiagnosisById(id) {
  return async () => {
    try {
      const response = await axios.get(`/api/diagnosis/${id}`);
      const { data, success } = response.data;
      if (parseInt(success) === 1) {
        dispatch(slice.actions.setCurrentDiagnosis(data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function save(tempParams) {
  return async () => {
    try {

      const params = { ...tempParams };

      let response = {}; 
      let action = '';
      if (params.id) {
        action = 'updated';
        response = await axios.put(`/api/diagnosis/${params.id}`, params);
      } else {
        action = 'added';
        response = await axios.post('/api/diagnosis', params);
      }
      const { data, success } = response.data;
      if (parseInt(success)) {
        dispatch(getDiagnosis(params));
        dispatch(
          openSnackbar({
            open: true,
            message: `Diagnosis ${action} successfully.`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: false
          })
        );
        //window.location.reload();
      } else {
        dispatch(
          openSnackbar({
            open: true,
            message: `Diagnosis was not ${action}. Please try again.`,
            variant: 'alert',
            alert: {
              color: 'error'
            },
            close: false
          })
        );
      }
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setCurrentDiagnosis(patient) {
  return async () => {
    dispatch(slice.actions.setCurrentDiagnosis(patient));
  };
}

export function deleteDiagnosis(tempParams) {
  return async () => {
    try {
      let response = {};
      const params = { ...tempParams };
      response = await axios.delete(`/api/diagnosis/${params.id}`, );
      const {success } = response.data;
      if (parseInt(success) === 1) {
        dispatch(getDiagnosis(tempParams));
    
        dispatch(
          openSnackbar({
            open: true,
            message: `Diagnosis deleted successfully.`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: false
          })
        );
        //window.location.reload();
      } else {
        dispatch(
          openSnackbar({
            open: true,
            message: `Diagnosis was not deleted. Please try again.`,
            variant: 'alert',
            alert: {
              color: 'error'
            },
            close: false
          })
        );
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDiagnosisFromDB() {
  return async () => {
    try {
      const response = await axios.get('/api/diagnosis/get_all');
      const { data } = response.data;
      dispatch(slice.actions.getDiagnosisFromDBSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
