// third-party
import { createSlice } from '@reduxjs/toolkit';

// project-imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ==============================|| SLICE - PRODUCTS ||============================== //

const initialState = {
  residents: []
};

const slice = createSlice({
  name: 'residents',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET consultants
    getResidentsSuccess(state, action) {
      state.residents = action.payload;
    },
  }
});

// Reducer
export default slice.reducer;

export function getResidents() {
  return async () => {
    try {
      const response = await axios.get('/api/residents');
      const { data } = response.data;
      dispatch(slice.actions.getResidentsSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
