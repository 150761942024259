// third-party
import { createSlice } from '@reduxjs/toolkit';

// project-imports
import axios from 'utils/axios';
import { dispatch } from '../index';
import { openSnackbar } from 'store/reducers/snackbar';
// ==============================|| SLICE - PRODUCTS ||============================== //

const initialState = {
  medications: [], //with patients associations
  currentMedication: {},
  medicationsFromDB: [] //for autocomplete
};

const slice = createSlice({
  name: 'medications',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET PATIENTS
    getMedicationSuccess(state, action) {
      state.medications = action.payload;
    },

    setCurrentMedication(state, action) {
      state.currentMedication = action.payload
    },

    getMedicationFromDBSuccess(state, action) {
      state.medicationsFromDB = action.payload;
    },
  }
});

// Reducer
export default slice.reducer;

export function getMedications(params) {
  return async () => {
    try {
      //console.log('getMedications', params);
      params.sort = params.sort ? params.sort : { 'by': 'id', 'order': 'desc' };

      const response = await axios.get('/api/medications', { params: { ...params } });
  
      const { data } = response.data;
      dispatch(slice.actions.getMedicationSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMedication(id) {
  return async () => {
    try {
      const response = await axios.get(`/api/medications/${id}`);
      const { data, success } = response.data;
      if (parseInt(success) === 1) {
        dispatch(slice.actions.setCurrentMedication(data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function save(tempParams) {
  return async () => {
    try {

      const params = { ...tempParams };

      let response = {}; 
      let action = '';
      if (params.id) {
        action = 'updated';
        response = await axios.put(`/api/medications/${params.id}`, params);
      } else {
        action = 'added';
        response = await axios.post('/api/medications', params);
      }
      const { data, success } = response.data;
      if (parseInt(success)) {
        dispatch(getMedications(params));
        dispatch(
          openSnackbar({
            open: true,
            message: `Medication ${action} successfully.`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: false
          })
        );
        //window.location.reload();
      } else {
        dispatch(
          openSnackbar({
            open: true,
            message: `Medication was not ${action}. Please try again.`,
            variant: 'alert',
            alert: {
              color: 'error'
            },
            close: false
          })
        );
      }
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setCurrentMedication(patient) {
  return async () => {
    dispatch(slice.actions.setCurrentMedication(patient));
  };
}

export function deleteMedication(tempParams) {
  return async () => {
    try {
      let response = {};
      const params = { ...tempParams };
      response = await axios.delete(`/api/medications/${params.id}`, );
      const {success } = response.data;
      if (parseInt(success) === 1) {
        dispatch(getMedications(tempParams));
    
        dispatch(
          openSnackbar({
            open: true,
            message: `Medication deleted successfully.`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: false
          })
        );
        //window.location.reload();
      } else {
        dispatch(
          openSnackbar({
            open: true,
            message: `Medication was not deleted. Please try again.`,
            variant: 'alert',
            alert: {
              color: 'error'
            },
            close: false
          })
        );
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMedicationsFromDB() {
  return async () => {
    try {
      const response = await axios.get('/api/medications/get_all');
      const { data } = response.data;
      dispatch(slice.actions.getMedicationFromDBSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
