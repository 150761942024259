// third-party
import { createSlice } from '@reduxjs/toolkit';

// project-imports
import axios from 'utils/axios';
import { dispatch } from '../index';
import { openSnackbar } from 'store/reducers/snackbar';
// ==============================|| SLICE - PRODUCTS ||============================== //

const initialState = {
  consultants: []
};

const slice = createSlice({
  name: 'consultants',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET consultants
    getConsultantsSuccess(state, action) {
      state.consultants = action.payload;
    },
  }
});

// Reducer
export default slice.reducer;

export function getConsultants() {
  return async () => {
    try {
      //console.log('trigger')
      const response = await axios.get('/api/consultants');
      const { data } = response.data;
      dispatch(slice.actions.getConsultantsSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
