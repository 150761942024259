// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  DocumentCode2,
  OceanProtocol,
  Level,
  ShieldCross,
  InfoCircle,
  I24Support,
  Driving,
  People,
  Hospital,
  SecurityUser,
  Book,
  CalendarAdd
} from 'iconsax-react';

// icons
const icons = {
  samplePage: DocumentCode2,
  menuLevel: OceanProtocol,
  menuLevelSubtitle: Level,
  disabledMenu: ShieldCross,
  chipMenu: InfoCircle,
  documentation: I24Support,
  roadmap: Driving,
  patients: People,
  residents: Hospital,
  users: SecurityUser,
  auditLog: Book,
  appointments: CalendarAdd
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const support = {
  id: 'menu',
  title: <FormattedMessage id="menu" />,
  type: 'group',
  children: [
    {
      id: 'appointments',
      title: <FormattedMessage id="appointments" />,
      type: 'item',
      url: '/appointments-page',
      icon: icons.appointments,
    },
    {
      id: 'patients',
      title: <FormattedMessage id="patients" />,
      type: 'item',
      url: '/patients',
      icon: icons.patients,
    },
    {
      id: 'residents',
      title: <FormattedMessage id="residents" />,
      type: 'item',
      url: '/residents',
      icon: icons.residents,
    },
    {
      id: 'users',
      title: <FormattedMessage id="consultants" />,
      type: 'item',
      url: '/consultants',
      icon: icons.users,
    }
    
  ]
};

export default support;
